import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { jwtDecode } from "jwt-decode";
import Navbar from "./components/Navbar";
import LoginPage from "./components/LoginPage";
import AdminPanel from "./components/AdminPanel";
import HomePage from "./components/HomePage";
import ProductPage from "./components/ProductPage";
import CategoryList from "./components/CategoryList";
import CategoryPage from "./components/CategoryPage";
import AdminProductForm from "./components/AdminProductForm";
import AdminProductList from "./components/AdminProductList";
import AdminCategoryList from "./components/AdminCategoryList";
import AdminCategoryForm from "./components/AdminCategoryForm";
import AdminRoute from "./components/AdminRoute";
import RegisterPage from "./components/RegisterPage";
import { registerUser } from "./services/authService";
import AccountPage from "./components/AccountPage";
import "./index.css";
import SpoofersPage from "./components/SpoofersPage";
import SpooferPage from "./components/SpooferPage";
import NotFound from "./components/NotFound";

const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState("");
  const navbarRef = useRef(null);
  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.clientHeight);
    }
  }, [navbarRef]);

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setIsAdmin(decodedToken.user.isAdmin);
      } catch (err) {
        console.error("Invalid token:", err);
      }
    }
  }, [token]);

  const handleLogin = (newToken) => {
    localStorage.setItem("token", newToken);
    setToken(newToken);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setToken(null);
    setIsAdmin(false);
  };

  const handleRegister = async (formData) => {
    try {
      const response = await registerUser(formData);
      localStorage.setItem("token", response.token);
      setToken(response.token);
      setIsAdmin(response.user.isAdmin);
      setError("");
    } catch (error) {
      console.error("Registration error:", error);
      setError("Registration failed. Please try again.");
    }
  };

  return (
    <HelmetProvider>
      <Router>
        <div>
          <Navbar
            ref={navbarRef}
            token={token}
            isAdmin={isAdmin}
            handleLogout={handleLogout}
          />

          {error && <p style={{ color: "red" }}>{error}</p>}
          <div className="app-container" style={{ paddingTop: navbarHeight }}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route
                path="/login"
                element={<LoginPage onLogin={handleLogin} />}
              />
              <Route
                path="/register"
                element={<RegisterPage onRegister={handleRegister} />}
              />
              <Route path="/categories" element={<CategoryList />} />
              <Route
                path="/admin"
                element={
                  <AdminRoute isAdmin={isAdmin}>
                    <AdminPanel />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/products"
                element={
                  <AdminRoute isAdmin={isAdmin}>
                    <AdminProductList />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/products/new"
                element={
                  <AdminRoute isAdmin={isAdmin}>
                    <AdminProductForm />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/products/edit/:id"
                element={
                  <AdminRoute isAdmin={isAdmin}>
                    <AdminProductForm />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/categories"
                element={
                  <AdminRoute isAdmin={isAdmin}>
                    <AdminCategoryList />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/categories/new"
                element={
                  <AdminRoute isAdmin={isAdmin}>
                    <AdminCategoryForm />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/categories/edit/:id"
                element={
                  <AdminRoute isAdmin={isAdmin}>
                    <AdminCategoryForm />
                  </AdminRoute>
                }
              />
              <Route
                path="/account"
                element={<AccountPage username="User" isAdmin={isAdmin} />}
              />
              <Route
                path="/category/:categoryName"
                element={<CategoryPage />}
              />
              <Route
                path="/category/:categoryName/:productName"
                element={<ProductPage />}
              />
              <Route path="*" element={<NotFound />} />
              <Route path="/spoofers" element={<SpoofersPage />} />
              <Route path="/spoofers/:productName" element={<SpooferPage />} />
            </Routes>
          </div>
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;
