// RegisterPage.js
import React, { useState } from "react";
import "../css/AuthForm.css";

const RegisterPage = ({ onRegister }) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    isAdmin: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password, confirmPassword, isAdmin } = formData;

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    if (password.length < 8) {
      setError("Password must be at least 8 characters long");
      return;
    }

    try {
      await onRegister({ username, password, isAdmin });
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.msg);
      } else {
        setError("Registration failed. Please try again.");
      }
    }
  };

  return (
    <div className="auth-form-container">
      <h2>Register</h2>

      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <div className="text">
            <p className="login">Username</p>
            <p className="mandatory">Обязательное поле</p>
          </div>
          <label className="login-input-label">
            <div className="login-container">
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={formData.username}
                onChange={handleChange}
                required
                className="login-input"
              />
            </div>
          </label>
        </div>
        <br />
        <div className="input-group">
          <div className="text">
            <p className="password">Password</p>
            <p className="mandatory">Обязательное поле</p>
          </div>
          <div className="password-input-label-button">
            <label className="password-input-label">
              <div className="password-container">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  className="password-input"
                />
                <button
                  type="button"
                  className="toggle-password-visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "Скрыть" : "Показать"}
                </button>
              </div>
            </label>
          </div>
        </div>
        <br />
        <div className="input-group">
          <div className="text">
            <p className="password">Confirm Password</p>
            <p className="mandatory">Обязательное поле</p>
          </div>
          <div className="password-input-label-button">
            <label className="password-input-label">
              <div className="password-container">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                  className="password-input"
                />
                <button
                  type="button"
                  className="toggle-password-visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? "Скрыть" : "Показать"}
                </button>
              </div>
            </label>
          </div>
        </div>
        <br />
        <div className="login-error">
          <button type="submit" className="auth-button">
            Зарегистрироваться
          </button>
          {error && <p className="error-message">{error}</p>}
        </div>
      </form>
    </div>
  );
};

export default RegisterPage;
