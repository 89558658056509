import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import "../css/Navbar.css";

const Navbar = forwardRef(({ token, isAdmin, handleLogout }, ref) => {
  return (
    <nav ref={ref} className="navbar">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to="/" className="nav-link">
            Главная
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/categories" className="nav-link">
            Категории
          </Link>
        </li>
        <li className="nav-item support-link">
          <Link to="/spoofers" className="nav-link">
            Спуферы
          </Link>
        </li>

        {!token && (
          <div className="log-reg">
            <li className="nav-item">
              <Link to="/login" className="nav-link">
                Войти
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/register" className="nav-link">
                Регистрация
              </Link>
            </li>
          </div>
        )}
        {token && (
          <li className="nav-item">
            <Link to="/account" className="nav-link">
              Аккаунт
            </Link>
          </li>
        )}
        {isAdmin && token && (
          <li className="nav-item">
            <Link to="/admin" className="nav-link">
              Admin Panel
            </Link>
          </li>
        )}
        {token && (
          <li className="nav-item">
            <button onClick={handleLogout} className="logout-button">
              Выйти
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
});

export default Navbar;
