const API_URL = "https://crosshack.store/api/products"; // Укажите URL вашего сервера

export const getProducts = async () => {
  const response = await fetch(API_URL);
  if (!response.ok) {
    throw new Error("Failed to fetch products");
  }
  return response.json();
};

export const getProduct = async (id) => {
  const response = await fetch(`${API_URL}/${id}`);
  if (!response.ok) {
    throw new Error(`Failed to fetch product ${id}`);
  }
  return response.json();
};

// productService.js

export const getCategoryByProductId = async (productId) => {
  try {
    const response = await fetch(`${API_URL}/${productId}/category`);
    if (!response.ok) {
      throw new Error("Failed to fetch category for product");
    }
    const category = await response.json();
    return category;
  } catch (error) {
    console.error("Error fetching category:", error);
    return null;
  }
};

export const getProductByCategoryAndName = async (
  categoryName,
  productName
) => {
  try {
    const url = `${API_URL}/productByName?categoryName=${encodeURIComponent(
      categoryName
    )}&productName=${encodeURIComponent(productName)}`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(
        `Failed to fetch product '${productName}' in category '${categoryName}'`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching product by category and name:", error);
    throw error;
  }
};

export const getProductsByCategoryName = async (categoryName) => {
  const response = await fetch(`${API_URL}/category/${categoryName}`);
  if (!response.ok) {
    throw new Error(`Failed to fetch products for category ${categoryName}`);
  }
  return response.json();
};

export const getCategories = async () => {
  const response = await fetch("http://localhost:5000/api/categories");
  if (!response.ok) {
    throw new Error("Failed to fetch categories");
  }
  return response.json();
};

export const addProduct = async (product) => {
  const response = await fetch(API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming token is stored in localStorage
    },
    body: JSON.stringify(product),
  });
  if (!response.ok) {
    throw new Error("Failed to add product");
  }
  return response.json();
};

export const updateProduct = async (id, product) => {
  const response = await fetch(`${API_URL}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming token is stored in localStorage
    },
    body: JSON.stringify(product),
  });
  if (!response.ok) {
    throw new Error(`Failed to update product ${id}`);
  }
  return response.json();
};

export const deleteProduct = async (id) => {
  const response = await fetch(`${API_URL}/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming token is stored in localStorage
    },
  });
  if (!response.ok) {
    throw new Error(`Failed to delete product ${id}`);
  }
  return response.json();
};
