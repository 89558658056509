import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getProductByCategoryAndName } from "../services/productService";
import "../css/ProductPage.css";
import { Helmet } from "react-helmet-async";
import Carousel from "./Carousel";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const ProductPage = () => {
  const { categoryName, productName } = useParams();
  const [product, setProduct] = useState(null);
  const [activeFeatureIndex, setActiveFeatureIndex] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productData = await getProductByCategoryAndName(
          categoryName,
          productName
        );
        if (!productData) {
          throw new Error("Empty response received");
        }
        setProduct(productData);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct();
  }, [categoryName, productName]);

  const handleFeatureClick = (index) => {
    setActiveFeatureIndex(index === activeFeatureIndex ? null : index);
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div className="product-page-container">
      <Helmet>
        <title>
          Купить приватный чит {product.name} для {categoryName}
        </title>
        <meta
          name="description"
          content={`Купить приватный чит ${
            product.name
          } для ${categoryName}. ${product.tags.join(
            ", "
          )} для ${categoryName}. Доминируйте над конкурентами и поднимите свой игровой опыт на новый уровень!`}
        />
        <meta
          property="og:title"
          content={`Купить приватный чит ${product.name} для ${categoryName}`}
        />
        <meta
          property="og:description"
          content={`Купить приватный чит ${
            product.name
          } для ${categoryName}. ${product.tags.join(
            ", "
          )} для ${categoryName}. Доминируйте над конкурентами и поднимите свой игровой опыт на новый уровень!`}
        />
        <meta property="og:image" content={product.images[0]} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <h1 className="product-title">
        Приватный чит {product.name} для {categoryName}
      </h1>
      <div className="carousel-container">
        <Carousel images={product.images} />
      </div>

      <div className="product-details">
        <h3>Системные требования:</h3>{" "}
        <p className="product-details">{product.systemRequirements}</p>
      </div>
      {product.video && (
        <video controls src={product.video} className="product-video" />
      )}

      <div className="feature-purchase">
        <div className="product-features">
          <h3>Функционал:</h3>
          {product.features &&
            product.features.map((feature, index) => (
              <div key={index} className="feature-dropdown">
                <button
                  className="feature-dropdown-button"
                  onClick={() => handleFeatureClick(index)}
                >
                  {feature.title}
                  {activeFeatureIndex === index ? (
                    <FaChevronUp className="chevron-icon" />
                  ) : (
                    <FaChevronDown className="chevron-icon" />
                  )}
                </button>
                {activeFeatureIndex === index && (
                  <p className="feature-description">{feature.description}</p>
                )}
              </div>
            ))}
        </div>
        <div className="product-purchase-options">
          <h3>Покупка:</h3>
          <ul>
            {product.purchaseOptions &&
              product.purchaseOptions.map((option, index) => (
                <li key={index}>
                  <a className="product-purchase" href={option.link}>
                    Купить чит на {option.days}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
