import React, { useState, useEffect } from "react";
import { getCategories } from "../services/categoryService";
import { Link } from "react-router-dom";
import "../css/CategoryList.css";
import { Helmet } from "react-helmet-async";

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const fetchedCategories = await getCategories();
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCategories = categories.filter(
    (category) =>
      category.name.toLowerCase() !== "spoofer" &&
      category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="category-list-container">
      <Helmet>
        <title>Категории | Solution - приватные читы</title>
        <meta
          property="og:title"
          content="Категории | Solution - приватные читы"
        />
      </Helmet>
      <h2 className="category-list-title">Категории</h2>
      <input
        type="text"
        placeholder="Поиск по категориям"
        value={searchTerm}
        onChange={handleSearchChange}
        className="search-input"
      />
      <ul className="category-list-ul">
        {filteredCategories.map((category) => (
          <li key={category._id} className="category-list-li">
            <Link
              to={`/category/${category.name}`}
              className="category-list-link"
            >
              <div
                className="background-image"
                style={{ backgroundImage: `url(${category.imageLink})` }}
              ></div>
              <div className="product-info glass">
                <h3 className="category-name">{category.name}</h3>
                <div className="count-link">
                  <p className="product-count">
                    Читов: ({category.productCount})
                  </p>
                  <p className="button-link">Перейти</p>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryList;
