import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getProducts, deleteProduct } from "../services/productService";
import "../css/AdminProductList.css"; // Import your CSS file

const AdminProductList = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const fetchedProducts = await getProducts();
        setProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteProduct(id);
      setProducts(products.filter((product) => product._id !== id));
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  return (
    <div className="admin-product-list">
      <h1>Products</h1>
      <Link to="/admin/products/new" className="admin-product-list-link">
        Add Product
      </Link>
      <ul>
        {products.map((product) => (
          <li key={product._id}>
            {product.name}
            <Link
              to={`/admin/products/edit/${product._id}`}
              className="admin-product-list-link"
            >
              Edit
            </Link>
            <button
              onClick={() => handleDelete(product._id)}
              className="admin-product-list-button"
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminProductList;
