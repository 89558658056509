import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addCategory,
  updateCategory,
  getCategory,
} from "../services/categoryService";
import "../css/AdminCategoryForm.css";

const AdminCategoryForm = () => {
  const [category, setCategory] = useState({ name: "", imageLink: "" }); // Добавляем imageLink в состояние
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const fetchCategory = async () => {
        try {
          const fetchedCategory = await getCategory(id);
          setCategory(fetchedCategory);
        } catch (error) {
          console.error("Error fetching category:", error);
        }
      };

      fetchCategory();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await updateCategory(id, category);
      } else {
        await addCategory(category);
      }
      navigate("/admin/categories");
    } catch (error) {
      console.error("Error saving category:", error);
    }
  };

  return (
    <div className="admin-category-form">
      <h1>{id ? "Edit Category" : "Add Category"}</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={category.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Image Link:</label>
          <input
            type="text"
            name="imageLink"
            value={category.imageLink}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Save</button>
      </form>
    </div>
  );
};

export default AdminCategoryForm;
