import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/AccountPage.css";

const AccountPage = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found");
        return;
      }

      try {
        const response = await axios.get("https://crosshack.store/api/user", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUserData(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Token is not valid or expired");
          // Handle token expiration or invalid token, possibly redirect to login
        } else {
          setError("Error fetching user data: " + error.message);
        }
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="account-container">
      <h2>Ваш аккаунт</h2>
      {error && <p className="error-message">{error}</p>}
      {userData && (
        <div className="account-info">
          <div className="info-block">
            <p>Имя пользователя:</p>
            <p>{userData.username}</p>
          </div>
          <div className="info-block">
            <p>Роль:</p>
            <p> {userData.isAdmin ? "Admin" : "User"}</p>
          </div>
          <div className="info-block">
            <p>
              Поздравляем с потраченным временем на бесполезную регистрацию! 🫢
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountPage;
