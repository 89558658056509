import React from "react";
import { Link } from "react-router-dom";
import { IoEyeOutline, IoChatbubbleEllipsesOutline } from "react-icons/io5";
import "../css/HomePage.css";
import { Helmet } from "react-helmet-async";
const HomePage = () => {
  return (
    <div className="home-page">
      <Helmet>
        <title>Главная | Solution Hacks</title>
        <meta property="og:title" content="Главная | Solution Hacks" />
      </Helmet>
      <div className="wp-block-column is-layout-flow wp-block-column-is-layout-flow">
        <div className="welcome-block">
          <video autoPlay loop muted playsInline className="video-background">
            <source src="/videos/background.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="content-over-video">
            <h1>
              Добро пожаловать на <span>Solution Hacks</span>
            </h1>
            <p>
              Мы предлагаем лучшие читы для ваших любимых игр. Присоединяйтесь к
              нам и улучшайте свой игровой опыт!
            </p>
            <Link to="/categories" className="catalog-button">
              <IoEyeOutline /> Посмотреть читы
            </Link>
          </div>
        </div>

        <div className="home-container">
          <div className="why-us-block">
            <h2>Наши преимущества</h2>
            <p>
              Проверенные читы от лучших разработчиков
              <br />
              Быстрая и надежная поддержка
              <br />
              Конфиденциальность и безопасность
            </p>
          </div>

          <div className="features-block">
            <div className="feature-item">
              <h3>100+ проверенных читов</h3>
              <p>
                Большой выбор надежных и эффективных читов для различных игр.
              </p>
            </div>
            <div className="feature-item">
              <h3>25+ игр</h3>
              <p>
                Поддержка более 25 популярных игр с нашими эксклюзивными читами.
              </p>
            </div>
          </div>

          <div className="mission-block">
            <h2>О нас</h2>
            <p>
              Solution Hacks - это место, где вы можете найти самые эффективные
              и безопасные читы для игр. Мы стремимся предложить нашим клиентам
              лучший сервис и продукты.
            </p>
          </div>

          <div className="contact-block">
            <h2>Свяжитесь с нами</h2>
            <p>
              У вас есть вопросы или нужна помощь? Обратитесь за помощью на наш
              Discord сервер.
            </p>
            <a href="https://discord.gg/rqYCH5MDJw" className="catalog-button">
              <IoChatbubbleEllipsesOutline /> Написать нам
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
