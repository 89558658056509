// src/services/authService.js
const API_URL = "https://crosshack.store/api/auth"; // Укажите URL вашего сервера

export const registerUser = async (formData) => {
  try {
    const response = await fetch(`${API_URL}/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.msg);
    }

    const data = await response.json();
    window.location.href = "/"; // Перенаправление после успешной регистрации
    return data;
  } catch (error) {
    throw error;
  }
};

export const loginUser = async (userData) => {
  try {
    const response = await fetch(`${API_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.msg);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
