import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCategories, deleteCategory } from "../services/categoryService";
import "../css/AdminCategoryList.css"; // Путь к вашему CSS файлу

const AdminCategoryList = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const fetchedCategories = await getCategories();
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteCategory(id);
      setCategories(categories.filter((category) => category._id !== id));
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  return (
    <div className="admin-category-list-container">
      <h1 className="admin-category-list-heading">Categories</h1>
      <Link to="/admin/categories/new" className="admin-category-list-link">
        Add Category
      </Link>
      <ul className="admin-category-list">
        {categories.map((category) => (
          <li key={category._id} className="admin-category-list-item">
            {category.name}
            <Link
              to={`/admin/categories/edit/${category._id}`}
              className="admin-category-list-link"
            >
              Edit
            </Link>
            <button
              onClick={() => handleDelete(category._id)}
              className="admin-category-list-button"
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminCategoryList;
