import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/AdminPanel.css"; // Путь к вашему CSS файлу

const AdminPanel = ({ token }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="admin-panel-container">
      <h2 className="admin-panel-title">Admin Panel</h2>
      <nav className="admin-nav">
        <ul className="admin-nav-list">
          <li>
            <Link to="/admin/products" className="admin-nav-link">
              Manage Products
            </Link>
          </li>
          <li>
            <Link to="/admin/categories" className="admin-nav-link">
              Manage Categories
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default AdminPanel;
